/**eslint-disble*/
import React, {  useState, useContext } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { Card } from 'react-bootstrap';
import { Box } from '@mui/material';
import Swal from 'sweetalert2';
import { db, uploadImageFile } from '../../base';
import {  doc, updateDoc } from 'firebase/firestore';
import { AppContext } from '../../context/provider';

function EditProfileForm({ data, onSubmit }) {

  const [state, setState] = useContext(AppContext);
  const [formData, setFormData] = useState(data);
  const [imagesName, setImagesName] = useState();
  const [imageData, setImageData] = useState();

  const openImagePicker = () => {
    Swal.fire({
      title: 'Seleccionar imagen',
      showCancelButton: true,
      confirmButtonText: 'Seleccionar',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        document.getElementById('upload-button').click();
      }
    });
  };

  const changeInput = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        setImageData(file)
        const fileName = file.name;
        setImagesName(fileName)

      } else {
        alert('El archivo seleccionado no es una imagen.');
      }
    } else {
      alert('No se ha seleccionado ningún archivo.');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setFormData((prevData) => ({
      ...prevData,
      'photo': imageData ? imageData : formData.photo
    }));

    
    try {
      const usersDocument = doc(db, 'users', data.id)

            const url = imageData ? await uploadImageFile(imageData, imagesName, 'ProfileFolder') : formData.photo;
            const dataToUpdate = {
                phone:formData.phone,
                numOrden:formData.numOrden,
                profession:formData.profession,
                medicalLicence:formData.medicalLicence,
                isNotColleged:formData.isNotColleged,
                email:formData.email,
                registration:formData.registration,
                category:formData.category,
                subscriptionIsActive:formData.subscriptionIsActive,
                name:formData.name,
                province:formData.province,
                address:formData.address,
                speciality:formData.speciality,
                cuil:formData.cuil,
                associateNumber: formData.associateNumber,
                photo: url ? url : formData.photo,
            };

            await updateDoc(usersDocument, dataToUpdate);
            
            // aqui quiero actualizar mi currentUser señor chatGPT
            const updatedCurrentUser = {
              ...state.currentUser, // Mantén los datos existentes
              phone: formData.phone,
              numOrden: formData.numOrden,
              profession: formData.profession,
              medicalLicence: formData.medicalLicence,
              isNotColleged: formData.isNotColleged,
              email: formData.email,
              registration: formData.registration,
              category: formData.category,
              subscriptionIsActive: formData.subscriptionIsActive,
              name: formData.name,
              province: formData.province,
              address: formData.address,
              speciality: formData.speciality,
              cuil: formData.cuil,
              associateNumber: formData.associateNumber,
              photo: url ? url : formData.photo, // Agrega la nueva URL de la foto
            };
          
            // Actualiza el currentUser en el estado global
            setState((prevState) => ({
              ...prevState,
              currentUser: updatedCurrentUser,
            }));

            Swal.fire({
              title: 'Guardado exitosamente!',
              icon: 'success',
              showCancelButton: true,
              confirmButtonText: 'OK',
          }).then((result) => {
              if (result.isConfirmed) {
                onSubmit(formData);
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                onSubmit(formData);
              }
          });

    } catch (error) {
      console.error(error);
      Swal.fire({
          title: 'Ocurrió un error!',
          icon: 'error',
          showCancelButton: true,
          confirmButtonText: 'OK',
      });
    }
     
  };



  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <div
            style={
              {
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }
            }>
            {imageData && <Card style={{
              width: '200px',
              backgroundSize: 'contain',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px'
            }
            }>
              <img
                src={imageData === 'string' ? imageData : URL.createObjectURL(imageData)}
                alt="Imagen"
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                }}
              />            
            </Card>}  
            {! imageData &&<Card style={{
              width: '200px',
              backgroundSize: 'contain',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px'
            }
            }>
                <img
                src={formData.photo}
                alt="Imagen"
                style={{
                  width: '200px',
                  height: '200px',
                  objectFit: 'contain',
                }}
              />
              <input
                type="file"
                multiple
                onChange={changeInput}
                style={{ display: 'none' }}
                id="upload-button"
              />
              <Button size="small" onClick={openImagePicker}>
                SELECCIONAR IMAGEN
              </Button>
            </Card>}
          </div>
        </Box>
        <TextField
          label="Nombre"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Categoría"
          name="category"
          value={formData.category}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Provincia"
          name="province"
          value={formData.province}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Domicilio / Direccion"
          name="address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Licencia Médica"
          name="medicalLicence"
          value={formData.medicalLicence}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="isNotColleged"
              checked={formData.isNotColleged}
              onChange={handleChange}
              color="primary"
            />
          }
          label="No colegiado"
        />
        <TextField
          label="Número de Orden"
          name="numOrden"
          value={formData.numOrden}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="subscriptionIsActive"
              checked={formData.subscriptionIsActive}
              onChange={handleChange}
              color="primary"
            />
          }
          label="Suscripción Activa"
        />
        <TextField
          label="Número de Asociado"
          name="associateNumber"
          value={formData.associateNumber}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Especialidad"
          name="speciality"
          value={formData.speciality}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Registro"
          name="registration"
          value={formData.registration}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Correo Electrónico"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Profesión"
          name="profession"
          value={formData.profession}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Teléfono"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="CUIL"
          name="cuil"
          value={formData.cuil}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
        <Button type="submit" variant="contained" color="success">
          Guardar
        </Button>
      </form>
    </Container>
  );
}

export default EditProfileForm;
