import React, { useState, useEffect, useContext } from "react";
import "./PatientManager.css";

// libraries
import { EditOutlined } from '@ant-design/icons';
import { Card, Search, Loading } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import axios from 'axios';

// utils
import { AppContext } from "../../context/provider";
import { db } from "../../base";

// constants
import texts from "../../constants/texts";
const initialState = {
  loading: true,
  isSearching: false,
  exists: false,
};

export const PatientManager = () => {
  const [statePM, setStatePM] = useState(initialState);
  const [state, setState] = useContext(AppContext);
  const [patient, setPatient] = useState({});
  const history = useHistory();

  useEffect(() => {

    if (state.selectedPatient) {
      onSearch(state.selectedPatient.dni && state.selectedPatient.dni);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = async (dni) => {
    setStatePM({ loading: true, isSearching: true });

    const patientsQuery = query(
      collection(db, "patients"),
      where("dni", "==", dni)
    );
    const querySnapshot = await getDocs(patientsQuery);

    let selectedPatient;
    querySnapshot.forEach((doc) => {
      selectedPatient = { id: doc.id, ...doc.data() };
    });
    // SI EXISTE EN FIRESTORE LO MUESTRA
    if (selectedPatient) {
      setState({ ...state, selectedPatient: selectedPatient, hdcs: [] });
      setPatient(selectedPatient);
      setStatePM({
        isSearching: true,
        loading: false,
        exists: true,
      });
    } else {
      // SI NO EXISTE EN FIRESTORE LO BUSCA EN EL API DE NEUQUEN
      const config = {
        url: `https://us-central1-tani-3a53a.cloudfunctions.net/patient?dni=${dni}`,
        method: "get",
      };

      fetchData(config);
    }

    if (!selectedPatient)
      setStatePM({ exists: false, isSearching: true, loading: true });

  };

  const fetchData = (config) => {
    axios(config)
      .then((response) => {
        const { data } = response.data;
        setState({
          ...state,
          selectedPatient: {
            name: data.name,
            dni: data.dni,
            phone: 0,
            organization: data.organization,
            gender: null,
            birthdate: null,
          },
          hdcs: []
        });
        setPatient({
          name: data.name,
          dni: data.dni,
          phone: 0,
          organization: data.organization,
          gender: null,
          birthdate: null,
        });
        setStatePM({
          isSearching: true,
          loading: false,
          exists: true,
        });
      })
      .catch((error) => {
        setState({
          ...state,
          selectedPatient: null,
          hdcs: []
        });
        setPatient({});
        setStatePM({
          isSearching: true,
          loading: false,
          exists: false,
        });
      });
  };



  const onClear = () => {
    delete state.selectedPatient;
    setState(prevState => ({ ...prevState, selectedPatient: null }));

  };

  const { isSearching, loading, exists } = statePM;
  const { name, organization } = patient;

  const searchBar = () => (

    <Search
      style={{ width: '95vw' }}
      placeholder='Ingresar DNI del paciente'
      onSearch={onSearch}
      onClear={onClear}
      maxLength={8}
    />

  );



  const {
    pages: {
      home: {
        components: {
          patientManager: {
            unknownPatientText,
            newPatientText,
          },
        },
      },
    },
  } = texts;




  const editPatient = () => {
    history.push('/edit-patient');
  }

  const newPatient = () => {
    history.push('/new-patient');
  }

  return (
    <>
      <Card title={searchBar()}>
        <div className="patient-manager-container">
          {isSearching &&
            (loading ? (
              <Loading />
            ) : exists ? (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div>
                  <div className="name-title">{`${name}`}</div>
                  <div className="organization-subtitle">{organization}</div>

                  {exists &&
                    ((!state.selectedPatient.gender &&
                      state.selectedPatient.gender !== "") ||
                      (!state.selectedPatient.dni &&
                        state.selectedPatient.dni !== "") ||
                      (!state.selectedPatient.name &&
                        state.selectedPatient.name !== "")) &&
                    <>
                      <hr />
                      <div className="organization-subtitle text-danger">Debe completar los datos obligatorios del paciente</div>
                    </>
                  }
                </div>

                <EditOutlined
                  className="editPatient__button"
                  onClick={editPatient}
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <div>
                  <div className="newPatient__text">{unknownPatientText}</div>
                  {/*cuando tenga esta opcion deberia limpiarse las tarjetas con las prestaciones*/}
                  <div className="newPatient__subtext">{newPatientText}</div>
                </div>

                <EditOutlined
                  onClick={newPatient}
                  className="editPatient__button"
                />
              </div>
            ))}
        </div>
      </Card>

    </>
  );
};
