import React from 'react'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'


export const Textarea = (props) => {
  const { label, name, ...rest } = props
  return (
    <div className='form-control border-0'>
      <label htmlFor={name} className='formik-label'>{label}</label>
      <Field as='textarea' className='formik-textarea' id={name} name={name} {...rest} />
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}
