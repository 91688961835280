import React from 'react'
import DateView from 'react-datepicker'
import { Field, ErrorMessage } from 'formik'
import TextError from './TextError'
import 'react-datepicker/dist/react-datepicker.css'

export const DatePicker = (props) => {
  const { label, name, ...rest } = props
  return (
    <div className='form-control border-0'>
      <label htmlFor={name}>{label}</label>
      <Field name={name}>
        {
          ({ form, field }) => {
            const { setFieldValue } = form
            const { value } = field
            return (
              <DateView
                id={name}
                {...field}
                {...rest}
                selected={value}
                onChange={val => setFieldValue(name, val)}
                placeholderText='Seleccione una fecha'
                className='formik-input'
              />
            )
          }
        }
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  )
}
