/* eslint-disable */
import React, { useState, useEffect } from 'react'
import { Formik, Form, useFormikContext } from 'formik'
import * as Yup from 'yup'
import FormikControl from './FormikControl'
import { useContext } from 'react';
import { AppContext } from '../../context/provider';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../base';
import Swal from 'sweetalert2';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const FormikContainer = (props) => {

  const [state, setState] = useContext(AppContext);
  const { selectedPatient, currentUser, claimResponse, professionClaims } = state;
  const { id } = currentUser;
  const { name: patientName, dni, organization } = selectedPatient;
  const { claim: { name: claimName } } = claimResponse;

  // const [users, setUsers] = useState([]);
  const [matricula, setMatricula] = useState();
  const [plus, setPlus] = useState(0);
  const [nombre, setNombre] = useState();
  const [isLoadingDate, setIsLoadingDate] = useState(true);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDateFormated, setSelectedDateFormated] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false);
  const maxDate = new Date()


  const obtenerFecha = async () => {

    await fetch('https://worldtimeapi.org/api/ip?timezone=America/Argentina/Buenos_Aires')

      .then(response => response.json())
      .then(data => {
        const currentDate = new Date(data.datetime);
        const userTimezoneOffset = currentDate.getTimezoneOffset() * 60000;
        const userDate = new Date(currentDate.getTime() + userTimezoneOffset);

        const fecha = new Date(userDate);
        const mes = fecha.getMonth() + 1;
        const dia = fecha.getDate();
        const anio = fecha.getFullYear();

        const fechaFormateada = `${mes.toString().padStart(2, '0')}/${dia.toString().padStart(2, '0')}/${anio}`;
        setSelectedDateFormated(fechaFormateada);
        setIsLoadingDate(false); // Indicar que la fecha ha sido cargada
      })
      .catch(error => console.error(error));
  }

  const handleSaveClaimResponse = async (values) => {
    console.log('plus', values.plus)
    const path = `users/${id}/claim-responses`;
    await addDoc(collection(db, path), {
      patient: patientName,
      dni: dni,
      claim: claimName,
      amount: 1000, // se debe traer el precio de la prestación
      status: 'active',
      organization: organization,
      registration: matricula != undefined ? matricula : values.registration,
      description: values.description,
      quantity: values.quantity,
      charge: values.charge,
      radioOption: values.radioOption,
      billingDate: values.billingDate,
      serviceDate: initialValues.serviceDate,
      autorizationCode: values.autorizationCode,
      plus: values.plus
    });
  };

  useEffect(() => {
    obtenerFecha()
  }, [])


  const { onSubmitClose } = props

  const radioOptions = [
    { key: 'Si', value: 'si' },
    { key: 'No', value: 'no' },
  ]

  const initialValues = {
    nome: state.currentUser.name,
    registration: matricula != undefined ? matricula : state.currentUser.medicalLicence === undefined ?
      (state[0].currentUser.medicalLicence ? state[0].currentUser.medicalLicence.slice(0, 6).padStart(6, '0') : '000000') :
      (state.currentUser.medicalLicence ? state.currentUser.medicalLicence.slice(0, 6).padStart(6, '0') : '000000'),
    description: '',
    quantity: '',
    charge: '',
    radioOption: '',
    billingDate: '',
    serviceDate: selectedDate === undefined ? selectedDateFormated : selectedDate,
    autorizationCode: ''
  }

  const validationSchema = Yup.object({
    registration: Yup.number().max(999999, 'Máximo 6 caracteres').min(1).required('Ingrese un número válido').positive('Ingrese un número válido').integer('Ingrese un número válido'),
    charge: Yup.number().max(240, 'Valor máximo: 240').min(0).positive('Ingrese un número válido').integer('Ingrese un número válido'),
    description: Yup.string().max(30, 'Máximo 30 caracteres'),
    autorizationCode: Yup.number().max(999999, 'Máximo 6 caracteres').min(1000, 'Ingrese un codigo superior a 4 caracteres ').required('Ingrese su codigo de autorización'),
    quantity: Yup.number().max(9, 'Ingrese un número del 0 al 9').positive('Ingrese un número válido').integer('Ingrese un número válido').min(0),
    radioOption: Yup.string().required('Seleccione una opción'),
    // billingDate: Yup.date().required('Seleccione una fecha').nullable(),
    // serviceDate: Yup.date().required('Seleccione una fecha').nullable()

  })


  const onSubmit = (values, { resetForm }) => {
    onSubmitClose();
    resetForm();
    handleSaveClaimResponse(values)
    setState({
      ...state,
      currentUser: state.currentUser,
      selectedPatient: state.selectedPatient,
    })
    Swal.fire({
      icon: 'success',
      title: 'Formulario enviado con éxito',
      showConfirmButton: false,
      timer: 2000,

    }).then(() => {
      window.location.reload();
    })

  }

  const SearchButton = () => {
    const formik = useFormikContext();
    // const user = collection(db, "users");
    const handleSearch = async () => {
      console.log('Search value', formik.values.nome);

      try {
        const profileQuery = query(
          collection(db, "users"),
          where("name", "==", formik.values.nome)
        );
        const querySnapshot = await getDocs(profileQuery);

        let selectedProfile;
        querySnapshot.forEach((doc) => {
          selectedProfile = doc.data();

        });
        if (selectedProfile) {
          // console.log('the one', selectedProfile)
          setNombre(selectedProfile.name)
          const matricul = selectedProfile.medicalLicence ? selectedProfile.medicalLicence.slice(0, 6).padStart(6, '0') : '000000';
          setMatricula(matricul)

        } else {
          setNombre('Sin coincidencias')
        }

      } catch (error) {
        console.log('error ', error)
        setNombre('Sin coincidencias')
      }


    };

    return (
      <button
        type='button'
        onClick={handleSearch}
        style={{
          width: '100%',
          marginLeft: '10px',
          marginBottom: '10px'
        }}
      >Buscar</button>
    );
  }

  const ElegirFecha = () => {
    const date = new Date();

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;
    setSelectedDateFormated(formattedDate)
    //console.log(selectedDateFormated); // Output: 03/04/2023


    const handleButtonClick = () => {
      setShowDatePicker(!showDatePicker);
    };

    return (
      <div style={{ width: '100%' }}>
        <button onClick={handleButtonClick} style={{ width: '100%' }}>Elegir fecha</button>
        {showDatePicker && (
          <DatePicker
            selected={date}
            onChange={date => setSelectedDate(date)}
            dateFormat="MM/dd/yyyy"
            calendarClassName="calendar-custom"
            maxDate={maxDate}
          />
        )}
      </div>
    );
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      <Form style={{ marginTop: "20px", backgroundColor: "" }}>
        <FormikControl
          control='input'
          type='number'
          label='Matricula del profesional solicitante'
          name='registration'
          onWheel={(e) => e.target.blur()}
          readOnly
        />
        <FormikControl
          control='input'
          type='text'
          label='Nombre del profesional solicitante'
          name='nome'
          onWheel={(e) => e.target.blur()}

        />
        <SearchButton />
        <p
          style={{
            paddingLeft: "10px",
          }}
        >DR. {' '}{nombre != undefined ? nombre : currentUser.name}</p>
        <FormikControl
          control='input'
          type='number'
          label='Codigo de autorización/bono'
          name='autorizationCode'
          onWheel={(e) => e.target.blur()}
        />
        {/* <FormikControl
          control='input'
          label='Fecha de prestación'
          name='serviceDate'
        /> */}
        {isLoadingDate ? (
          <p style={{ paddingLeft: '10px' }}>Cargando fecha ...</p>
        ) : (
          <p style={{ paddingLeft: '10px' }}>{selectedDateFormated}</p>
        )}
        <ElegirFecha />

        <FormikControl
          control='input'
          type='number'
          label='Opcional: Cantidad'
          name='quantity'
          onWheel={(e) => e.target.blur()}
        />
        <FormikControl
          control='select'
          label='Opcional: Recargo'
          name='charge'
          options={[
            { value: '000', label: '0%' },
            { value: '025', label: '25%' },
            { value: '050', label: '50%' },
            { value: '100', label: '100%' },
          ]}
        />
        <FormikControl
          control='textarea'
          label='Opcional: Diagnóstico'
          name='description'
        />
        <FormikControl
          control='radio'
          label='¿Primera consulta?'
          name='radioOption'
          options={radioOptions}
        />
        <FormikControl
          control='input'
          type='number'
          label='Plus de practica $'
          name='plus'
          onWheel={(e) => e.target.blur()}
        />
        <button className='submitButton' variant="success" type="submit" >Aceptar</button >
      </Form>
    </Formik>
  )
}

export default FormikContainer