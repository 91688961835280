import React, { useContext } from "react";
import "./floatingPanel.css";

// libraries
import { Dropdown } from 'antd-mobile'
import { DownOutline } from 'antd-mobile-icons'
//import Swal from 'sweetalert2';

// utils
import { AppContext } from "../../context/provider";

// constants
import texts from '../../constants/texts';
import {
  Link,
  //useHistory 
} from 'react-router-dom';
import { useAccounting } from "../../hooks";
// import { useState } from "react";

export const PanelAnimate = () => {
  const [state] = useContext(AppContext);
  // const history = useHistory();
  const { formatedCurrency } = useAccounting()
  const { totalCancelled, totalActive
    // , userClaims 
  } = state;
  // const [plus, setPlus] = useState(0)

  const {
    pages: {
      home: {
        components: {
          floatingPanel: {
            toCollectText,
            toInvoiceText,
            // toPlus
          },
        },
      },
    },
  } = texts;

  const total = formatedCurrency(totalActive + totalCancelled)

  // useEffect(() => {
  //   if (userClaims.length > 0) {
  //     const claimsWithPlus = userClaims.filter(claim => claim.plus !== undefined && claim.plus !== null);
  //     const fechaActual = new Date();
  //     const mesActual = fechaActual.getMonth() + 1;
  //     const añoActual = fechaActual.getFullYear();
  //     const porFecha = claimsWithPlus.filter((item) => {
  //       let servicioFecha;
  //       if (typeof item.serviceDate === 'string') {
  //         const [mesServicio, diaServicio, añoServicio] = item.serviceDate.split('/');
  //         servicioFecha = new Date(`${mesServicio}/${diaServicio}/${añoServicio}`);
  //       } else if (item.serviceDate && item.serviceDate.seconds) {
  //         servicioFecha = new Date(item.serviceDate.seconds * 1000);
  //       } else {
  //         return false;
  //       }
  //       return servicioFecha.getFullYear() === añoActual && servicioFecha.getMonth() + 1 === mesActual;
  //     });
  //     const sumatoriaPlus = porFecha.reduce((acumulador, item) => acumulador + item.plus, 0);
  //     setPlus(sumatoriaPlus);
  //   }
  //   console.log('userClaims....', JSON.stringify(userClaims, null, 5))
  // }, [userClaims]);
  

  // const obtenerFechaActual = () => {
  //   const fecha = new Date();
  //   const mes = (fecha.getMonth() + 1).toString().padStart(2, '0'); // Se suma 1 porque los meses van de 0 a 11
  //   const año = fecha.getFullYear();
  //   const fechaCompleta = new Date(`${mes}/01/${año}`);
  //   return fechaCompleta;
  // };

  //  const SwalCustom = Swal.mixin({
  //   customClass: {
  //      confirmButton: "modal__btn-success",
  //     cancelButton: "modal__btn-danger",
  //    },
  //   buttonsStyling: false,
  // });
  //const subscriptionAlert = () => {
  //    if (state.currentUser.subscriptionIsActive === false) {
  //      SwalCustom.fire({
  //       title: subscriptionAlertTitle,
  //       text: subscriptionAlertText,
  //       icon: "info",
  //       confirmButtonText: subscriptionAlertButtonText,
  //     }).then((result) => {
  //       if (result.isConfirmed) {
  //         history.push("/subscription");
  //       } else if (result.dismiss === Swal.DismissReason.backdrop) {
  //         history.push("/");
  //       }
  //     });
  //   }
  // };

  return (
    <div className="panel">
      <Dropdown>
        <Dropdown.Item key='more' title={total} arrow={<DownOutline />}>
          <div>
            <div className='panel_container'>
              <div className="panel-item">
                <Link
                  to='/payment-reconciliations'
                  style={{ marginBottom: 30, cursor: 'pointer', textDecoration: 'none' }}
                  className={`${totalCancelled === 0 ? 'disabled-link' : ''}`}
                //onClick={subscriptionAlert}
                >
                  <h2>
                    {formatedCurrency(totalCancelled)}
                  </h2>
                  <h3>{toCollectText}</h3>
                </Link>
              </div>
              <div className="panel-item">
                <Link
                  to='/generate-invoice'
                  style={{ textDecoration: 'none' }}
                  className={`${totalActive === 0 ? 'disabled-link' : ''}`}
                //onClick={subscriptionAlert}
                >
                  <h2>
                    {formatedCurrency(totalActive)}
                  </h2>
                  <h3>{toInvoiceText}</h3>
                </Link>
              </div>
             {/*} <div className="panel-item">
                <Link
                  to='/plus'
                  style={{ textDecoration: 'none' }}>
                  <h2>
                    {formatedCurrency(plus)}
                  </h2>
                  <h3> {toPlus}</h3>
                </Link>
              </div> */}
            </div>
          </div>
        </Dropdown.Item>
      </Dropdown>
    </div>
  );
};
