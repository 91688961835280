// Select.js
import React from 'react';
import { Field, ErrorMessage } from 'formik';
import TextError from './TextError';
import './FormikForm.css';

const Select = (props) => {
  const { label, name, options, ...rest } = props;

  return (
    <div className='form-control border-0'>
      <label htmlFor={name} className='formik-label'>
        {label}
      </label>
      <Field as='select' id={name} className='formik-input' name={name} {...rest}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Field>
      <ErrorMessage name={name} component={TextError} />
    </div>
  );
};

export default Select;
