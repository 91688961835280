import  {useContext} from 'react'
import {  collection, getDocs, query, where } from "firebase/firestore";
import { AppContext } from '../context/provider';
import { db } from '../base';

const useTurnos = () => {

    const [state, setState] = useContext(AppContext);
    const { currentUser } = state;

    const traerTurnos = async (fecha) => {
       

        const appointmentQuery = query(
            collection(db, "appointments"),
            where("idDoctor", "==", currentUser.id),
            where("date", "==", fecha)
        );
        const querySnapshot = await getDocs(appointmentQuery);

        let arrayAppointment = []; // Inicializa un array vacío

        querySnapshot.forEach((doc) => {
            const appointmentData = doc.data();
            const appointmentWithId = { id: doc.id, ...appointmentData }; // Agrega el ID al objeto
            arrayAppointment.push(appointmentWithId); // Agrega cada documento al array
        });
        // SI EXISTE EN FIRESTORE LO MUESTRA
        if (arrayAppointment.length > 0) {
            arrayAppointment.sort((a, b) => {
                const timeA = new Date(`2000-01-01T${a.time}`);
                const timeB = new Date(`2000-01-01T${b.time}`);
                return timeA - timeB; 
              });

            setState({ ...state, appointments: arrayAppointment });
        }
    };
  return {traerTurnos}
}

export default useTurnos